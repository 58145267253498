/* eslint-disable @typescript-eslint/naming-convention */
import { inject, Injectable, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CartResponse } from '@victoria-company/agora-client';
import { CatalogService } from './V2/catalog.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticService {
  loaded = false;
  GA_TRAKINK_ID: string;

  catalogService = inject(CatalogService);

  constructor() {}

  //Call Once in AppComponent
  public createGAElements(contextId: number, render: Renderer2, document: Document) {
    // if (this.loaded || !environment.production) return;

    this.setGATrackingID(contextId);

    let element = render.createElement('script');
    element.src = this.loadGAScripts();
    element.async = true;
    render.appendChild(document.body, element);

    element = render.createElement('script');
    element.text = this.initPagePreviewHit();
    render.appendChild(document.body, element);

    console.log('GA Element Created');
  }

  public async GARemoveFromCart(productVariantId: string, contextId: number, locale: string) {
    if (window.dataLayer && environment.production) {
      const product = (await this.catalogService.getProductsByVariantIdsPromise(contextId, locale, [productVariantId]))?.products[0];
      const variant = product?.variants?.find(v => v.id == productVariantId);

      if (!product && !variant) return;

      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name: product.name,
                id: product.reference,
                price: variant.currentPrice,
                brand: 'Victoria',
                category: product.category?.slug,
                variant: variant.sku,
                quantity: 1,
              },
            ],
          },
        },
      });

      console.log('GARemoveFromCart Send!');
    }
  }

  public async GAAddToCart(productVariantId: string, contextId: number, locale: string) {
    if (window.dataLayer && environment.production) {
      const product = (await this.catalogService.getProductsByVariantIdsPromise(contextId, locale, [productVariantId]))?.products[0];
      const variant = product?.variants?.find(v => v.id == productVariantId);

      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [
              {
                name: product.name,
                id: product.reference,
                price: variant.currentPrice,
                brand: 'Victoria',
                category: product.category?.slug,
                variant: variant.sku,
                quantity: 1,
              },
            ],
          },
        },
      });
      (window as any).rudderanalytics?.track('addToCart', {
        products: [
          {
            name: product.name,
            id: product.reference,
            price: variant.currentPrice,
            category: product.category?.slug,
            variant: variant.sku,
            quantity: 1,
          },
        ],
      });

      console.log('GAAddToCart Send!');
    }
  }

  public GACheckout(step: number, option: string, order: any) {
    let products = [];
    const cioProducts = [];
    order.orderItems.forEach(product => {
      products.push({
        name: product.productGroup?.nameOnly,
        id: product.productGroup?.code,
        price: product.unitPrice,
        brand: 'Victoria',
        category: product.productGroup?.category?.slug,
        variant: product.sku,
        quantity: product.quantity,
      });
      cioProducts.push({
        name: product.productGroup?.nameOnly,
        product_id: product.productGroup?.code,
        price: product.unitPrice,
        brand: 'Victoria',
        category: product.productGroup?.category?.slug,
        sku: product.sku,
        quantity: product.quantity,
      });
    });

    (window as any).rudderanalytics?.track('checkout', {
      order_id: order.id,
      revenue: order.totalToPay,
      shipping: order.deliveryFee,
      discount: 0,
      currency: 'EUR',
      products: cioProducts,
      value: order.totalToPay,
    });

    if (window.dataLayer && environment.production) {
      products = [];
      order.orderItems.forEach(product => {
        products.push({
          name: product.productGroup?.nameOnly,
          id: product.productGroup?.code,
          price: product.unitPrice,
          brand: 'Victoria',
          category: product.productGroup?.category?.slug,
          variant: product.sku,
          quantity: product.quantity,
        });
      });

      const checkoutDatas = {
        actionField: { step, option },
        products,
      };

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: checkoutDatas,
        },
      });
    }
  }

  public async GAOrderConfirmed(cart: CartResponse, contextId: number, locale: string) {
    if (window.dataLayer && environment.production) {
      const catalogItems = await this.catalogService.getProductsByVariantIdsPromise(
        contextId,
        locale,
        cart.cartItems.map(ci => ci.productVariantId)
      );
      if (!catalogItems.products?.length) return;

      const products = [];
      const cioProducts = [];

      cart.cartItems.forEach(ci => {
        const product = catalogItems.products.find(p => p.variants.some(v => v.id == ci.productVariantId));
        const variant = product?.variants.find(v => v.id == ci.productVariantId);

        if (product && variant) {
          products.push({
            name: product.name,
            id: product.reference,
            price: ci.price,
            brand: 'Victoria',
            category: product.category?.slug,
            variant: variant.sku,
            quantity: 1,
          });

          cioProducts.push({
            name: product.name,
            product_id: product.reference,
            price: ci.price,
            brand: 'Victoria',
            category: product.category?.slug,
            sku: variant.sku,
            quantity: 1,
          });
        }
      });

      console.log('GA Total to pay : ', cart.totalToPay);

      const purchaseDatas = {
        actionField: {
          id: cart.id,
          affiliation: 'Victoria online shop',
          revenue: cart.totalToPay,
          shipping: cart.deliveryFee,
          items: cart.cartItems.length,
        },
        products,
      };

      console.log('GA purchase data: ', purchaseDatas);

      (window as any).rudderanalytics?.track('purchase', {
        order_id: cart.id,
        revenue: cart.totalToPay,
        shipping: cart.deliveryFee,
        discount: 0,
        currency: 'EUR',
        products: cioProducts,
        value: cart.totalToPay,
      });
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: purchaseDatas,
        },
      });

      console.log('GAOrderConfirmed Send!');
    }
  }

  private setGATrackingID(contextId: number) {
    switch (contextId) {
      case 1:
        this.GA_TRAKINK_ID = 'GTM-NVTMTL4';
        break;
      case 2:
        this.GA_TRAKINK_ID = 'GTM-5LMF6SM';
        break;
      case 3:
        this.GA_TRAKINK_ID = 'GTM-53J29LR';
        break;
      default:
        this.GA_TRAKINK_ID = '0';
        break;
    }
  }

  private loadGAScripts() {
    return `https://www.googletagmanager.com/gtm.js?id=${this.GA_TRAKINK_ID}`;
  }

  private initPagePreviewHit() {
    this.loaded = true;
    return `
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${this.GA_TRAKINK_ID}');
      `;
  }
}
